.img-transform {
    max-width: 100%;
    height: calc(100vh - 42px);
    display: flex;
    text-align: center;
}

.img-style {
    max-width: 100%;
    max-height: 98%;
    display: inline-block;
}